import React, { useState, useEffect } from 'react'
import ProjectLayout from '../pages/ProjectLayout'
import '../App.css';

const SevenSummer = () => {
    const allProjects = require("../data/projects.json")
    const thisProject = allProjects.filter(project => project.name === "776 Summer Street")

    const title = thisProject[0].name
    const description = thisProject[0].description

    const content = 
    <>
        <img src="../cover-images/cbt-776-summer.jpg" className="w-full" />
    </>
    
   return (
        <ProjectLayout 
            title = {title}
            description = {description}
            content = {content}
        />
   )
}

export default SevenSummer