import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import SideNav from '../components/SideNav'
import Footer from '../components/Footer'
import ProjectIntro from '../components/ProjectIntro'
import { Link, useLocation } from 'react-router-dom'
import '../App.css';

const ProjectLayout = (props) => {
    
   return (
      <>
            <div className="row justify-center">
                <div className="col-12 pr-36">
                    <Navbar />
                </div>
            </div>
            <SideNav />
            <div className="row justify-end content-start py-8 mt-24 px-16 overflow-y-hidden">
               <div className="col-10 text-left">
                  <Link to="/projects" className="text-xl duration-300 hover:text-sky-200">← Back</Link>
               </div>
            </div>
            <div className="row justify-end content-start py-8 px-16 overflow-y-hidden">
               <div className="col-10">
                  <ProjectIntro 
                     title={props.title}
                     description = {props.description}
                  />
               </div>
            </div>
            <div className="row justify-end content-start py-8 mt-24 pl-16 pr-12 overflow-y-hidden">
               <div className="col-10">
                  {props.content}
               </div>
            </div>
            <Footer />
        </>
   )
}

export default ProjectLayout