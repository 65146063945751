import React from 'react'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'

function AboutPage(){
    return(
        <>
            <div className="row justify-center">
                <div className="col-12 pr-36">
                    <Navbar />
                </div>
            </div>
            <div className="row justify-center h-screen content-center">
                <div className="col-8 text-left px-36">
                    <p className="text-4xl mb-10">
                        This is About Page.
                    </p>
                </div>

                <div className="col-4">
                </div>
            </div>
        </>
    )
}

export default AboutPage