import React from 'react'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'
import Resume from '../assets/220407_I-yang_resume.pdf'

import LinkedIn from '../assets/icons/linkedin.png'
import Email from '../assets/icons/email.png'
import Instagram from '../assets/icons/instagram.png'
// import kpfLogo from '../assets/icons/kpf.png'
// import mitLogo from '../assets/icons/mit.png'
// import mitmlLogo from '../assets/icons/media-lab.png'
// import tfLogo from '../assets/icons/tf.png'
// import gsdLogo from '../assets/icons/gsd.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {withRouter} from "react-router-dom";

function HomePage(){
    return(
        <>
            <div className="row justify-center">
                <div className="col-12 pr-36">
                    <Navbar />
                </div>
            </div>
            <div className="row justify-center content-center px-12 my-40">
                <div className="col-6 text-left">
                    <p className="text-4xl mb-10">
                        I-Yang is a multidisciplinary designer and creative technologist. 
                        He currently works at a commercial architectural firm in Boston, MA designing state-of-the-art science labs and highend condos. 
                    </p>
                    <a href={Resume} target="_blank" className="text-4xl hover:text-sky-200"> Resume →</a>
                    <Link to="/projects" className="text-4xl ml-20 hover:text-sky-200"> Projects →</Link>
                    <div className="mt-32 App-sans">
                       <p className="font-medium my-3">Work Experience</p>
                       <p className="my-1"><span className="font-medium">Architectural Designer</span> → <a href="https://www.cbtarchitects.com/" target="_blank" className="underline underline-offset-4 decoration-dotted">CBT Architects</a>, Boston MA, 2021-Present</p>
                       <p className="my-1"><span className="font-medium">Architectural Designer</span> → <a href="https://www.kpf.com/" target="_blank" className="underline underline-offset-4 decoration-dotted">Kohn Pederson Fox (KPF)</a>, New York NY, 2020</p>
                       <p className="my-1"><span className="font-medium">Designer</span> → <a href="https://tekumafrenchman.com/" target="_blank" className="underline underline-offset-4 decoration-dotted">Tekuma Frenchman Urban Design</a>, Boston MA, 2018-2019</p>
                       <p className="my-1"><span className="font-medium">Research Assistant</span> → <a href="https://www.media.mit.edu/" target="_blank" className="underline underline-offset-4 decoration-dotted">MIT Media Lab</a>, Cambridge MA, 2015-2016</p>
                    </div>
                    <div className="flex mt-10">
                        <a href="https://www.linkedin.com/in/i-yang-huang-assoc-aia-8352a969/" target="_blank"><img src={LinkedIn} alt="Logo" className="h-8 w-auto mr-4 icon"/></a>
                        <a href="mailto:h1261613@gmail.com" target="_blank"><img src={Email} alt="Logo" className="h-8 w-auto mr-4 icon"/></a>
                        <a href="https://www.instagram.com/hhhiyang/" target="_blank"><img src={Instagram} alt="Logo" className="h-8 w-auto mr-4 icon"/></a>
                    </div>
                </div>

                <div className="col-6">
                </div>
            </div>
            {/* <div className="row justify-center h-screen content-center">
                <div className="col-12 text-left px-36">
                    <div className="row flex mt-10">
                        <img src={cbtLogo} alt="Logo" className="h-8 w-auto mr-3 opacity-60" />
                        <img src={kpfLogo} alt="Logo" className="h-8 w-auto mx-3 opacity-60" />
                        <img src={mitLogo} alt="Logo" className="h-8 w-auto mx-3 opacity-60" />
                        <img src={mitmlLogo} alt="Logo" className="h-8 w-auto mr-3 opacity-60" />
                        <img src={tfLogo} alt="Logo" className="h-8 w-auto mx-3 opacity-60" />
                        <img src={gsdLogo} alt="Logo" className="h-8 w-auto mx-3 opacity-60" />
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default HomePage