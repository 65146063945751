import './App.css';
import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { LandingPage } from "./pages/Landing";
import HomePage from "./pages/Home";
import ProjectsPage from "./pages/Projects";
import AboutPage from "./pages/About";

import ThePinch from "./projects/ThePinch";
import OneCanal from "./projects/110Canal";
import FiveEightFiveArts from "./projects/585Arts";
import SevenSummer from "./projects/776Summer";
import Eskyiu from "./projects/Eskyiu";
import SenseableCity from "./projects/SenseableCity";


import UnFound from "./pages/UnFound";
//import ProtectedRoute from "./ProtectedRoute";

function App() {
  const [isAuth, setIsAuth] = useState(false);

  if(isAuth === false){
    return(
      <BrowserRouter>
        <div className="App overflow-x-hidden">
          <Routes>
            <Route exact path='/' element={<LandingPage isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
            <Route path="/*" element={<UnFound />}/>
          </Routes>
        </div>
      </BrowserRouter>
    )
  }else{
    return(
      <BrowserRouter>
        <div className="App overflow-x-hidden">
          <Routes>
            <Route exact path='/' element={<LandingPage isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
            <Route exact path='/home' element={<HomePage/>} />
            <Route exact path='/projects' element={<ProjectsPage/>} />
            <Route exact path='/about' element={<AboutPage/>} />

            <Route exact path='/projects/the-pinch' element={<ThePinch/>} />
            <Route exact path='/projects/110-canal' element={<OneCanal/>} />
            <Route exact path='/projects/585-arts' element={<FiveEightFiveArts/>} />
            <Route exact path='/projects/776-summer' element={<SevenSummer/>} />
            <Route exact path='/projects/eskyiu' element={<Eskyiu/>} />
            <Route exact path='/projects/senseable-city-lab' element={<SenseableCity/>} />
           
            <Route path="/*" element={<UnFound />}/>
          </Routes>
        </div>
      </BrowserRouter>
    )
  }

}

export default App;
