import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../App.css';

const ProjectCatalog = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const allProjects = require("../data/projects.json")
        setProjects(allProjects)
    },[])

    //assigning location variable
    const location = useLocation();

    const coverImg = projects.map((project) => {
        
        return(
            <div className="col-5" key={project.name}>
                <Link to={project.to}>
                    <img src={project.cover} className="w-full px-2 duration-300 hover:opacity-70"/>
                </Link>
                <Link to={project.to}>
                    <div className="text-4xl text-left font-bold my-4 px-2 hover:text-black hover:underline hover:underline-offset-4">{project.name}</div>
                </Link>
                <div className="text-2xl text-left mb-24 pr-4 px-2">{project.description}</div>
            </div>
        )
    })

   return (
       <>
        {coverImg}
       </>
   )
}

export default ProjectCatalog