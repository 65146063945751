import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../App.css';

const SideNav = () => {
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        const allRoutes = require("../data/projects.json")
        setRoutes(allRoutes)
    },[])

    //assigning location variable
    const location = useLocation();

    const routePro = routes.map((route) => {
        if(route.category === "Professional"){
            return(
                <div className="link-div" key={route.name}> 
                    <Link to={route.to} className="text-xl text-black font-medium no-underline hover:underline hover:underline-offset-4" 
                        style={{ 
                            // textDecoration: location.pathname === route.to? 'underline': 'none',
                            // textUnderlineOffset: location.pathname === route.to? '2px': 'none',
                            // textDecorationThickness: location.pathname === route.to? '2px': 'none',
                            // opacity: location.pathname === route.to? '1': '0.7',
                            fontWeight: location.pathname === route.to? 'bold': 'medium',
                        }}
                    > {route.name} </Link>
                    {/* <span 
                        style={{ 
                            visibility: location.pathname === route.to? 'visible': 'hidden'
                        }}
                    >←</span> */}
                </div>
            )
        }
    })

    const routeAca = routes.map((route) => {
        if(route.category === "Academic"){
            return(
                <div className="link-div" key={route.name}> 
                    <Link to={route.to} className="text-xl text-black font-medium no-underline hover:underline hover:underline-offset-4" 
                        style={{ 
                            // textDecoration: location.pathname === route.to? 'line-through': 'none',
                            // opacity: location.pathname === route.to? '1': '0.7',
                        }}
                    > {route.name} </Link>
                </div>
            )
        }
    })

   return (
       <div className="fixed px-12 py-8 mt-24 text-xl text-left">
           <div className="font-bold mb-2">Professional</div>
           {routePro}

           <div className="font-extrabold mt-12 mb-2">Academic</div>
           {routeAca}
       </div>
   )
}

export default SideNav