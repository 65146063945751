import React from 'react'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'

function UnFound(){
    return(
        <>
            <div className="row justify-center h-screen content-center">
                <div className="col-8 text-left px-36">
                    <p className="text-4xl">
                       Oops! You seem to have ventured into no man's land ...
                    </p>
                    <div className="my-4">
                        <Link to="/" className="text-2xl px-9 py-2 rounded-full drop-shadow-md enter-btn"> Go back </Link>
                    </div>
                    
                </div>
                <div className="col-4">
                    
                </div>
            </div>
        </>
    )
}

export default UnFound