import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../App.css';

const ProjectIntro = (props) => {
    
   return (
       <>
        <div className="text-left text-8xl">{props.title}</div>
        <div className="text-left text-2xl my-4 w-3/4">{props.description}</div>
       </>
   )
}

export default ProjectIntro