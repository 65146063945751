import React from 'react'
import Navbar from '../components/Navbar'
import SideNav from '../components/SideNav'
import ProjectCatalog from '../components/ProjectCatalog'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

function ProjectsPage(){
    return(
        <>
            <div className="row justify-center">
                <div className="col-12 pr-36">
                    <Navbar />
                </div>
            </div>
            <SideNav />
            <div className="row justify-end content-start py-8 mt-24 pr-12 overflow-y-hidden">
                <ProjectCatalog />
            </div>
            <Footer />
        </>
    )
}

export default ProjectsPage