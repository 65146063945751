import React from 'react'
import linkedin from '../assets/icons/linkedin.png'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export const LandingPage = (props) => {
    const [input, setInput] = useState("");
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const checkInput = (e) => {
        e.preventDefault();
        if(input === "hiy!"){
            props.setIsAuth(true)
            navigate("/home")
        }else{
            setError(true)
            navigate("/")
            setInput("")
            setTimeout(() => {
                setError(false)
            }, "2500")
        }
    }

    const handleChange = (e) => {
        setInput(e.target.value);
    }

    return (
        <>
            {error === true &&
                <div className="fixed h-screen w-screen z-50 error-modal row items-start px-96">
                    <div className="text-2xl py-4 rounded-xl bg-red-500 drop-shadow-lg text-white border border-current">Oops! Incorrect password.</div>
                </div>
            }
            <div className="row justify-center h-screen content-center">
                <div className="col-8 text-left px-36">
                    <p className="text-4xl">
                        I-Yang is a multidisciplinary designer and creative technologist. 
                        He currently works at a commercial architectural firm in Boston, MA designing state-of-the-art science labs and highend condos. 
                    </p>
                    {/* <a>
                        <img src={linkedin} alt="linkedin-logo" className="w-8 h-8" />
                    </a> */}
                    <br></br><br></br>
                    {/* Portfolio coming soon. */}
                    <form onSubmit={checkInput} className="enter-form">
                        <input type="password" value={input} placeholder="Password" onChange={handleChange} className="border-b-2 border-black focus:ring-0 focus:outline-none" required />
                        <input type="submit" value="Enter" className="border-2 rounded-full drop-shadow-md py-0.5 px-9 mx-4 enter-btn" />
                    </form>
                </div>
                <div className="col-4">
                    
                </div>
            </div>
        </>
    )
}