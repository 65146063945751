import React from 'react'
import '../App.css';

const Footer = () => {
    
   return (
      <div className="row justify-end text-right py-8 px-12 font-bold overflow-y-hidden">
            <p>© 2022 I-Yang Huang</p>
            <p>Developed with React.js + Tailwind CSS by <a href="https://github.com/617650" target="_blank" className="text-indigo-500">Tiange Wang</a></p>
      </div>
   )
}

export default Footer