import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import '../App.css';

const NavBar = () => {
     //assigning location variable
     const location = useLocation();

     //destructuring pathname from location
     const { pathname } = location;
 
     //Javascript split method to get the name of the path in array
     const splitLocation = pathname.split("/");

    return (
        <div className="fixed row w-full px-12 py-8">
            <div className="col-3">
                <div className="flex flex-row">
                    <Link to="/home" className="text-black text-xl hover:font-bold"> I-Yang Huang </Link>
                </div>
            </div>
            <div className="col-9 pr-0">
                <div className="flex flex-row-reverse text-xl">
                    <NavLink to="/about" className={splitLocation[1] === "about" ? "active-link text-black ml-4 hover:border-b-2 border-black" : "text-black ml-4 hover:border-b-2 border-black"}> 
                        About 
                    </NavLink>
                    <NavLink to="/projects" className={splitLocation[1] === "projects" ? "active-link text-black mx-4 hover:border-b-2 border-black" : "text-black mx-4 hover:border-b-2 border-black"}> 
                        Projects 
                    </NavLink>
                    <NavLink to="/home" className={splitLocation[1] === "home" ? "active-link text-black mr-4 hover:border-b-2 border-black" : "text-black mr-4 hover:border-b-2 border-black"}> 
                        Home 
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default NavBar